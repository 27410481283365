import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import BannerMain from '../components/bannerMain/bannerMain';
import SectionScaffolding from '../sections/scaffolding/sectionScaffolding';
import SectionFormwork from '../sections/formwork/sectionFormwork';
import SectionLifters from '../sections/lifters/sectionLifters';
import SectionFences from '../sections/fences/sectionFences';
import SectionBenefits from '../sections/benefits/sectionBenefits';
import EUBanner from '../components/EUBanner/EUBanner';
import Footer from '../components/footer/footer';

const IndexPage = ({ data }) => {
  const sections = Object.entries(data.prismicStronaGlowna.data).reduce(
    (acc, curr) => {
      const [key, value] = curr;
      let target;

      if (key.includes('_baner')) {
        target = 'banner';
      } else if (key.includes('_rusztowania')) {
        target = 'scaffolding';
      } else if (key.includes('_szalunki')) {
        target = 'formwork';
      } else if (key.includes('_drabiny_podnosniki')) {
        target = 'lifters';
      } else if (key.includes('_ogrodzenia')) {
        target = 'fences';
      } else {
        target = 'meta';
      }

      acc[target][key] = value;
      return acc;
    },
    {
      banner: {},
      scaffolding: {},
      formwork: {},
      lifters: {},
      fences: {},
      meta: {},
    }
  );

  const { banner, scaffolding, formwork, lifters, fences, meta } = sections;

  const seoData =
    meta.body_meta && meta.body_meta.find(el => el.slice_type === 'seo');

  return (
    <>
      <SEO
        title={seoData.primary.tytul}
        description={seoData.primary.opis}
        url="/"
      />

      <BannerMain
        title={banner.tytul_sekcji_baner.html}
        text={banner.naglowek_sekcji_baner.html}
        image={{
          alt: banner.zdjecie_1_baner.alt,
          image: banner.zdjecie_1_baner.localFile.childImageSharp.fluid,
        }}
      />

      <SectionScaffolding
        title={scaffolding.tytul_sekcji_rusztowania.text}
        heading={scaffolding.naglowek_sekcji_rusztowania.html}
        image1={{
          alt: scaffolding.zdjecie_1_rusztowania.alt,
          image:
            scaffolding.zdjecie_1_rusztowania.localFile.childImageSharp.fluid,
        }}
        image2={{
          alt: scaffolding.zdjecie_2_rusztowania.alt,
          image:
            scaffolding.zdjecie_2_rusztowania.localFile.childImageSharp.fluid,
        }}
        image3={{
          alt: scaffolding.zdjecie_3_rusztowania.alt,
          image:
            scaffolding.zdjecie_3_rusztowania.localFile.childImageSharp.fluid,
        }}
        links={scaffolding.body_rusztowania[0]}
        css="margin-bottom: 80px;"
      />

      <SectionFormwork
        title={formwork.tytul_sekcji_szalunki.text}
        heading={formwork.naglowek_sekcji_szalunki.html}
        image1={{
          alt: formwork.zdjecie_1_szalunki.alt,
          image: formwork.zdjecie_1_szalunki.localFile.childImageSharp.fluid,
        }}
        links={formwork.body_szalunki.find(
          el => el.slice_type === 'lista_linkow'
        )}
        features={
          formwork.body_szalunki.find(el => el.slice_type === 'sekcja_kafelki')
            .items
        }
        css="margin-bottom: 80px;"
      />

      <SectionLifters
        title={lifters.tytul_sekcji_drabiny_podnosniki.text}
        heading={lifters.naglowek_sekcji_drabiny_podnosniki.html}
        content={lifters.tresc_sekcji_drabiny_podnosniki.html}
        image1={{
          alt: lifters.zdjecie_1_drabiny_podnosniki.alt,
          image:
            lifters.zdjecie_1_drabiny_podnosniki.localFile.childImageSharp
              .fluid,
        }}
        image2={{
          alt: lifters.zdjecie_2_drabiny_podnosniki.alt,
          image:
            lifters.zdjecie_2_drabiny_podnosniki.localFile.childImageSharp
              .fluid,
        }}
        features={
          lifters.body_drabiny_podnosniki.find(
            el => el.slice_type === 'sekcja_kafelki'
          ).items
        }
        css="margin-bottom: 80px;"
      />

      <SectionFences
        title={fences.tytul_sekcji_ogrodzenia.text}
        heading={fences.naglowek_sekcji_ogrodzenia.html}
        image1={{
          alt: fences.zdjecie_1_ogrodzenia.alt,
          image: fences.zdjecie_1_ogrodzenia.localFile.childImageSharp.fluid,
        }}
        links={fences.body_ogrodzenia.find(
          el => el.slice_type === 'lista_linkow'
        )}
        css="margin-bottom: 80px;"
      />

      <SectionBenefits />

      <EUBanner />

      <Footer />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    prismicStronaGlowna {
      data {
        # BANER
        zdjecie_1_baner {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 2450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji_baner {
          html
        }
        naglowek_sekcji_baner {
          html
        }
        # RUSZTOWANIA
        zdjecie_1_rusztowania {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_2_rusztowania {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_3_rusztowania {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji_rusztowania {
          text
        }
        naglowek_sekcji_rusztowania {
          html
        }
        body_rusztowania {
          primary {
            tytul {
              text
            }
          }
          slice_type
          items {
            strona {
              document {
                data {
                  slug
                }
              }
            }
            tytul {
              text
            }
            tekst {
              html
            }
          }
        }
        # SZALUNKI
        zdjecie_1_szalunki {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji_szalunki {
          text
        }
        naglowek_sekcji_szalunki {
          html
        }
        body_szalunki {
          __typename
          ... on PrismicStronaGlownaBodySzalunkiListaLinkow {
            slice_type
            primary {
              tytul_nad_lista {
                text
              }
            }
            items {
              strona {
                document {
                  data {
                    slug
                  }
                }
              }
              tytul {
                text
              }
            }
          }
          ... on PrismicStronaGlownaBodySzalunkiSekcjaKafelki {
            slice_type
            items {
              ikona {
                alt
                localFile {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              tytul {
                text
              }
            }
          }
        }
        # PODNOSNIKI
        tytul_sekcji_drabiny_podnosniki {
          text
        }
        naglowek_sekcji_drabiny_podnosniki {
          html
        }
        tresc_sekcji_drabiny_podnosniki {
          html
        }
        zdjecie_1_drabiny_podnosniki {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        zdjecie_2_drabiny_podnosniki {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body_drabiny_podnosniki {
          slice_type
          items {
            ikona {
              alt
              localFile {
                childImageSharp {
                  fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            tytul {
              text
            }
          }
        }
        # OGRODZENIA
        zdjecie_1_ogrodzenia {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji_ogrodzenia {
          text
        }
        naglowek_sekcji_ogrodzenia {
          html
        }
        body_ogrodzenia {
          slice_type
          primary {
            tytul {
              text
            }
          }
          slice_type
          items {
            strona {
              document {
                data {
                  slug
                }
              }
            }
            tytul {
              text
            }
            tekst {
              html
            }
          }
        }
        # META
        body_meta {
          slice_type
          primary {
            tytul
            opis
          }
        }
      }
    }
  }
`;

export default IndexPage;
