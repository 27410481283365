import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from '../../components/container/container';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import FeaturesList from '../../components/featuresList/featuresList';
import VerticalText from '../../components/verticalText/verticalText';
import { mobileFirstMedia as media } from '../../config/theme';

import {
  Grid,
  HtmlContentArea,
  HeadingArea,
  FeaturesListArea,
  Image1Area,
  Image1,
  BackgroundImageArea,
  BackgroundImage,
  ButtonArea,
  Background,
} from './styles';

const SectionLifters = ({
  title,
  heading,
  content,
  features,
  image1,
  image2,
  ...rest
}) => {
  return (
    <section {...rest}>
      <Container
        width={1920}
        css={`
          background: black;

          @media (${media.m}) {
            background: none;
            padding-left: 130px;
          }

          @media (min-width: 1690px) {
            padding-left: 330px;
          }
        `}
      >
        <Grid>
          <HeadingArea data-aos="fade-left">
            <Heading title={title} heading={heading} />
          </HeadingArea>

          <VerticalText text="DRABINY I PODNOŚNIKI" />

          <HtmlContentArea
            dangerouslySetInnerHTML={{ __html: content }}
            data-aos="fade-left"
            data-aos-delay="100"
          />

          <FeaturesListArea>
            <FeaturesList features={features} bare />
          </FeaturesListArea>

          <Image1Area data-aos="fade-in" data-aos-delay="100">
            <Image1 fluid={image1.image} alt={image1.alt} />
          </Image1Area>

          <BackgroundImageArea data-aos="fade-in" data-aos-delay="300">
            <BackgroundImage fluid={image2.image} alt={image2.alt} />
          </BackgroundImageArea>

          <ButtonArea data-aos="fade-in" data-aos-delay="200">
            <Button component={Link} to="/drabiny" title="Drabiny - PRO-MEN">
              ZOBACZ WSZYSTKIE
            </Button>
          </ButtonArea>

          <Background data-aos="fade-up" />
        </Grid>
      </Container>
    </section>
  );
};

SectionLifters.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
};

export default SectionLifters;
