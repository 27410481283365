import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobileFirstMedia as media } from '../../config/theme';

const HeadingBase = styled.span`
  display: none;
  position: absolute;
  text-transform: uppercase;
  top: 0;

  color: ${({ theme }) => theme.colors.lightgrey};

  @media (${media.m}) {
    display: block;
  }
`;

const HeadingLeft = styled(HeadingBase)`
  left: 0;
  transform: rotate(270deg) translate(calc(-100% + 1em), -0.8em);
  transform-origin: bottom left;
`;

const HeadingRight = styled(HeadingBase)`
  right: 0;
  transform: rotate(90deg) translate(calc(100% - 1em), -0.8em);
  transform-origin: bottom right;
`;

const VerticalText = ({ text, right = false, ...rest }) => {
  const Tag = right ? HeadingRight : HeadingLeft;

  return (
    <Tag {...rest} className={`${rest.className} h1`}>
      {text}
    </Tag>
  );
};

VerticalText.propTypes = {
  text: PropTypes.string.isRequired,
  right: PropTypes.bool,
};

export default VerticalText;
