import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from '../../components/container/container';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import LinksList from '../../components/linksList/linksList';
import VerticalText from '../../components/verticalText/verticalText';
import { mobileFirstMedia as media } from '../../config/theme';

import {
  Grid,
  HeadingArea,
  LinksListArea,
  Image1Area,
  Image1,
  ButtonArea,
} from './styles';

const SectionFences = ({ title, heading, image1, links, ...rest }) => {
  return (
    <section {...rest}>
      <Container
        width={1920}
        css={`
          @media (${media.s}) {
            padding: 0 50px;
          }

          @media (${media.m}) {
            padding: 0 130px 0 50px;
          }

          @media (${media.l}) {
            padding: 0 130px;
          }

          @media (min-width: 1690px) {
            padding: 0 330px;
          }
        `}
      >
        <Grid>
          <HeadingArea data-aos="fade-right">
            <Heading title={title} heading={heading} />
          </HeadingArea>

          <VerticalText text="AKCESORIA" right />

          <LinksListArea data-aos="fade-right" data-aos-delay="100">
            <LinksList
              heading={links.primary.tytul.text}
              links={links.items}
              prefix="/ogrodzenia"
            />
          </LinksListArea>

          <Image1Area data-aos="fade-left" data-aos-delay="100">
            <Image1 fluid={image1.image} alt={image1.alt} />
          </Image1Area>

          <ButtonArea data-aos="fade-in" data-aos-delay="100">
            <Button
              component={Link}
              to="/ogrodzenia"
              title="Ogrodzenia - PRO-MEN"
            >
              ZOBACZ WSZYSTKIE
            </Button>
          </ButtonArea>
        </Grid>
      </Container>
    </section>
  );
};

SectionFences.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
};

export default SectionFences;
