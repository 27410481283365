import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { mobileFirstMedia as media } from '../../config/theme';

import FormattedHtml from '../../components/formattedHtml/formattedHtml';

export const Grid = styled.div`
  display: grid;
  grid-gap: 50px;
  position: relative;
  color: white;
  padding-top: 40px;

  @media (${media.xs}) {
    padding: 40px 0 60px 0;
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: minmax(250px, 30%) 30% 1fr;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (${media.l}) {
    padding-right: 0;
    grid-template-columns: 25% 25% 1fr;
  }
`;

export const HeadingArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 3;
  }
`;

export const HtmlContentArea = styled(FormattedHtml)`
  text-align: justify;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 4;
  }

  @media (${media.l}) {
    grid-column: 1 / 3;
  }
`;

export const FeaturesListArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
    grid-row: 3 / 5;
  }

  @media (${media.l}) {
    grid-column: 1 / 2;
  }
`;

export const Image1Area = styled.div`
  margin: 0 -25px;

  @media (${media.xs}) {
    margin: 0;
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 4;
    z-index: 1;
  }

  @media (${media.l}) {
    grid-column: 2 / 3;
  }
`;

export const Image1 = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  max-height: 320px;
`;

export const BackgroundImageArea = styled.div`
  display: none;

  @media (${media.l}) {
    display: block;
    grid-column: 3 / 4;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      100% 0,
      100% calc(100% - calc(var(--cropSize) * 1.5)),
      calc(100% - calc(var(--cropSize) * 1.5)) 100%,
      0 100%,
      0 0
    );
    z-index: -1;
  }
`;

export const BackgroundImage = styled(GatsbyImage)`
  height: 100%;

  @media (${media.m}) {
    z-index: -1;
    max-height: unset;
  }
`;

export const ButtonArea = styled.div`
  justify-self: center;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    justify-self: start;
  }
`;

export const Background = styled.div`
  clip-path: polygon(
    100% 0,
    100% calc(100% - calc(var(--cropSize) * 1.5)),
    calc(100% - calc(var(--cropSize) * 1.5)) 100%,
    0 100%,
    0 0
  );

  @media (${media.m}) {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -2;
  }
`;
