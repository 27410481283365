import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from '../../components/container/container';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import LinksList from '../../components/linksList/linksList';
import VerticalText from '../../components/verticalText/verticalText';
import { mobileFirstMedia as media } from '../../config/theme';

import {
  Grid,
  HeadingArea,
  LinksListArea,
  Image1,
  Image1Area,
  Image2,
  Image2Area,
  Image3,
  Image3Area,
  ButtonArea,
} from './styles';

const SectionScaffolding = ({
  title,
  heading,
  image1,
  image2,
  image3,
  links,
  ...rest
}) => {
  return (
    <section {...rest}>
      <Container
        width={1920}
        css={`
          @media (${media.s}) {
            padding: 0 50px;
          }

          @media (${media.m}) {
            padding: 0 50px 0 130px;
          }

          @media (${media.l}) {
            padding: 0 130px;
          }

          @media (min-width: 1690px) {
            padding: 0 330px;
          }
        `}
      >
        <Grid>
          <HeadingArea data-aos="fade-up" data-aos-delay="300">
            <Heading title={title} heading={heading} />
          </HeadingArea>

          <VerticalText text="RUSZTOWANIA" />

          <LinksListArea data-aos="fade-up" data-aos-delay="400">
            <LinksList
              heading={links.primary.tytul.text}
              links={links.items}
              prefix="/rusztowania"
            />
          </LinksListArea>

          <Image1Area data-aos="zoom-in" data-aos-delay="400">
            <Image1 fluid={image1.image} alt={image1.alt} />
          </Image1Area>

          <Image2Area data-aos="zoom-in" data-aos-delay="500">
            <Image2 fluid={image2.image} alt={image2.alt} />
          </Image2Area>

          <Image3Area data-aos="zoom-in" data-aos-delay="600">
            <Image3
              fluid={image3.image}
              alt={image3.alt}
              imgStyle={{ objectPosition: 'bottom right' }}
            />
          </Image3Area>

          <ButtonArea data-aos="fade-up" data-aos-delay="500">
            <Button
              component={Link}
              to="/rusztowania"
              title="Rusztowania - PRO-MEN"
            >
              ZOBACZ WSZYSTKIE
            </Button>
          </ButtonArea>
        </Grid>
      </Container>
    </section>
  );
};

SectionScaffolding.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  image3: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
};

export default SectionScaffolding;
