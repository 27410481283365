import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { mobileFirstMedia as media } from '../../config/theme';

export const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  position: relative;

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 40% 30% 1fr 1fr;
  }
`;

export const HeadingArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 1 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;

export const LinksListArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 1 / 3;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
  }
`;

export const Image1Area = styled.div`
  margin: 0px -25px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    margin: 0;
    grid-column: 1 / 3;
  }

  @media (${media.s}) {
    grid-column: 3 / 5;
  }

  @media (${media.m}) {
    grid-area: 1 / 2 / 3 / 4;
  }
`;

export const Image1 = styled(GatsbyImage)`
  height: 100%;
  clip-path: polygon(
    var(--cropSize) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 var(--cropSize)
  );
`;

export const Image2Area = styled.div`
  display: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.m}) {
    display: block;
    grid-column: 2 / 3;
    grid-row: 3 / 5;
  }
`;

export const Image2 = styled(GatsbyImage)`
  height: 280px;

  @media (${media.m}) {
    clip-path: polygon(
      100% 0,
      100% calc(100% - calc(var(--cropSize) / 1.5)),
      calc(100% - calc(var(--cropSize) / 1.5)) 100%,
      0 100%,
      0 0
    );
  }
`;

export const Image3Area = styled.div`
  display: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    display: block;
    grid-column: 3 / 5;
    /* grid-row: 3 / 4; */
  }

  @media (${media.s}) {
    grid-column: 3 / 5;
  }

  @media (${media.m}) {
    grid-area: 4 / 2 / 5 / 4;
  }

  @media (${media.m}) {
    height: 150%;
    margin-top: 60px;
    grid-area: 2 / 3 / 3 / 5;
  }
`;

export const Image3 = styled(GatsbyImage)`
  height: 100%;
  clip-path: polygon(
    100% 0,
    100% calc(100% - var(--cropSize)),
    calc(100% - var(--cropSize)) 100%,
    0 100%,
    0 0
  );
`;

export const ButtonArea = styled.div`
  justify-self: center;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-area: 4 / 1 / 5 / 2;
    align-self: end;
    justify-self: end;
    grid-row: 4 / 5;
  }
`;
