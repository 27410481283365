import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Container from '../../components/container/container';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import LinksList from '../../components/linksList/linksList';
import FeaturesList from '../../components/featuresList/featuresList';
import VerticalText from '../../components/verticalText/verticalText';
import Typography from '../../components/typography/typography';
import { mobileFirstMedia as media } from '../../config/theme';

import {
  Grid,
  HeadingArea,
  LinksListArea,
  FeaturesListArea,
  Image1Area,
  Image1,
  ButtonArea,
} from './styles';

const SectionFormwork = ({
  title,
  heading,
  image1,
  links,
  features,
  ...rest
}) => {
  return (
    <section {...rest}>
      <Container
        width={1920}
        css={`
          @media (${media.s}) {
            padding: 0 50px;
          }

          @media (${media.m}) {
            padding-right: 130px;
          }

          @media (min-width: 1690px) {
            padding-right: 330px;
          }
        `}
      >
        <Grid>
          <HeadingArea data-aos="fade-left">
            <Heading title={title} heading={heading} />
          </HeadingArea>

          <VerticalText text="SZALUNKI" right />

          <LinksListArea data-aos="fade-left" data-aos-delay="100">
            <LinksList
              heading={links.primary.tytul_nad_lista.text}
              links={links.items}
              prefix="/szalunki"
            />
          </LinksListArea>

          <FeaturesListArea>
            <Typography variant="h4" color="primary" css="margin-bottom: 30px;">
              Zalety naszych szalunków
            </Typography>

            <FeaturesList features={features} />
          </FeaturesListArea>

          <Image1Area data-aos="fade-right">
            <Image1 fluid={image1.image} alt={image1.alt} />
          </Image1Area>

          <ButtonArea data-aos="fade-left" data-aos-delay="100">
            <Button component={Link} to="/szalunki" title="Szalunki - PRO-MEN">
              ZOBACZ WSZYSTKIE
            </Button>
          </ButtonArea>
        </Grid>
      </Container>
    </section>
  );
};

SectionFormwork.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
};

export default SectionFormwork;
