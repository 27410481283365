import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Typography from '../typography/typography';

const List = styled.ul`
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.3em;
`;

const ListHeading = styled.p`
  margin-bottom: 1.5em;
  font-weight: 500;
`;

const LinksList = ({ heading, links, prefix }) => (
  <>
    <ListHeading>{heading}</ListHeading>

    <List>
      {links.map(({ strona, tytul, tekst }) => (
        <ListItem key={strona.document[0].data.slug}>
          <Link
            to={`${prefix}/${strona.document[0].data.slug}`}
            title={`${tytul.texts} - PRO-MEN`}
          >
            <Typography
              variant="h4"
              css="font-weight: 500; display: inline-block;"
            >
              {tytul.text}
            </Typography>

            {tekst && (
              <div
                dangerouslySetInnerHTML={{ __html: tekst.html }}
                css="margin-bottom: 1.2em;"
              />
            )}
          </Link>
        </ListItem>
      ))}
    </List>
  </>
);

LinksList.propTypes = {
  heading: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default LinksList;
