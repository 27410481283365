import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { mobileFirstMedia as media } from '../../config/theme';

export const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  position: relative;
  /* overflow-x: hidden; */

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 55% 1fr;
  }
`;

export const HeadingArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 3 / 5;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
  }
`;

export const LinksListArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }

  @media (${media.s}) {
    grid-column: 3 / 5;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
`;

export const FeaturesListArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
  }
`;

export const Image1Area = styled.div`
  margin: 0px -25px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    margin: 0;
    grid-column: 2 / 4;
    grid-row: 4 / 5;
  }

  @media (${media.s}) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
    grid-row: 1 / 5;
  }
`;

export const Image1 = styled(GatsbyImage)`
  height: 100%;
  max-height: 680px;
  clip-path: polygon(
    var(--cropSize) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 var(--cropSize)
  );
`;

export const ButtonArea = styled.div`
  justify-self: center;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
    align-self: end;
    justify-self: start;
  }
`;
