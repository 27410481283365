import styled from 'styled-components';
import Img from 'gatsby-image';

import { media /* mobileFirstMedia as mMedia */ } from '../../config/theme';

export const FooterBase = styled.footer`
  width: ${({ theme }) => theme.breakpoints.xl};
  width: 100%;
  max-width: 100%;
  padding: 4em 0;
  margin: auto;
  margin-top: 40px;
  position: relative;

  ${media.s`
    margin-top: 0;
  `}
`;

const defaultColumnsL = 'repeat(auto-fit, minmax(200px, 1fr))';

const mapColumnsL = '1fr 1fr minmax(40%, 45%)';

const mapColumnsM = '1fr 1fr';

export const FooterGrid = styled.div`
  min-height: 220px;
  position: relative;
  display: grid;
  grid-row-gap: 40px;
  grid-template-columns: ${({ showMap }) =>
    showMap ? mapColumnsL : defaultColumnsL};

  ${media.m`
    grid-template-columns: ${({ showMap }) =>
      showMap ? mapColumnsM : defaultColumnsL};
  `}

  ${media.s`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;
  /* grid-area: 1 / 1 / 2 / 2; */

  ${media.m`
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    justify-self: center;
  `}

  ${media.s`
    grid-column: unset;
    grid-row: 4 / 5;
  `}
`;

export const Logo = styled(Img)`
  /* width: 100%; */
  width: 250px;

  ${media.m`
    width: 200px;
  `}
`;

export const NavigationArea = styled.div`
  /* grid-area: 1 / 2 / 2 / 3; */

  /* ${media.m`
    display: none;
  `} */
`;

export const AddressArea = styled.div`
  /* grid-area: 1 / 3 / 2 / 4; */
`;

export const ContactArea = styled.div`
  /* grid-area: 1 / 4 / 2 / 5; */
`;

export const MapArea = styled.div`
  ${media.m`
    clip-path: polygon( var(--cropSize) 0,100% 0,100% 100%,0 100%,0 var(--cropSize) );
    height: 250px;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  `}

  ${media.s`
    width: calc(100% + 50px);
    grid-column: 1 / 2;
    margin: 0 -25px;
  `}
`;

export const FooterSection = styled.div`
  height: 100%;
  padding: 0 1.5em;
  border-left: 2px solid ${({ theme }) => theme.colors.lightgrey};

  ${media.s`
    border-left: none;
    text-align: center;
  `}
`;

export const NavList = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 1em;
  list-style-type: none;
  text-transform: uppercase;
`;

export const NavItem = styled.li`
  margin: 0;

  & > a {
    cursor: pointer;
  }
`;

export const ContactLink = styled.a`
  display: flex;
  align-items: center;

  svg {
    font-size: 1em;
    margin-right: 10px;
  }

  ${media.m`
    justify-content: center;
  `}
`;
