import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const FeaturesListWrapper = styled.ul`
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ bare }) => (bare ? '110px' : '90px')}, 1fr)
  );
  grid-gap: 30px;
  overflow-y: hidden;
`;

const Feature = styled.li`
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
`;

const FeatureMedia = styled.div`
  width: ${({ bare }) => (bare ? '110px' : '80px')};
  height: ${({ bare }) => (bare ? '110px' : '80px')};
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ bare }) => (bare ? '0' : '20px')};
  background: ${({ bare }) => (bare ? 'none' : '#a9a9a9')};

  img {
    max-width: 100%;
  }
`;

const FeaturesList = ({ features, bare = false }) => {
  const flattened = features.reduce((acc, curr) => {
    const {
      tytul: { text },
      ikona: {
        localFile: {
          childImageSharp: { fixed: image },
        },
      },
    } = curr;

    acc.push({ text, image });

    return acc;
  }, []);

  const renderFeatures = () =>
    flattened.map((feature, index) => (
      <Feature
        key={index}
        data-aos="fade-up"
        data-aos-delay={100 + 100 * index}
      >
        <FeatureMedia bare={bare}>
          {feature.image && (
            <Img
              fixed={feature.image}
              style={{ width: '60%', height: '60%' }}
            />
          )}
        </FeatureMedia>

        <p
          css={`
            color: ${bare ? ({ theme }) => theme.colors.primary : 'initial'};
            font-weight: ${bare ? '500' : 'initial'};
          `}
        >
          {feature.text}
        </p>
      </Feature>
    ));

  return (
    <FeaturesListWrapper bare={bare}>{renderFeatures()}</FeaturesListWrapper>
  );
};

FeaturesList.propTypes = {
  features: PropTypes.array.isRequired,
  bare: PropTypes.bool,
};

export default FeaturesList;
