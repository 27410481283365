import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import EmailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import Container from '../container/container';
import Typography from '../typography/typography';
import VerticalText from '../verticalText/verticalText';
import Map from '../map/map';
import { mobileFirstMedia as media } from '../../config/theme';
import { footerMenu } from '../../config/navigation';
import {
  FooterBase,
  FooterGrid,
  NavigationArea,
  AddressArea,
  ContactArea,
  LogoArea,
  Logo,
  MapArea,
  FooterSection,
  NavList,
  NavItem,
  ContactLink,
} from './styles';

const isClient = typeof window !== 'undefined';

const Footer = ({ showMap = false, ...rest }) => {
  const {
    prismicStronaKontakt: {
      data: {
        nazwa_firmy: companyName,
        adres_firmy: { html: addressHtml, text: addressString },
        kontakt_telefoniczny,
        sekretariat,
      },
    },
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  const { telefon_1: phone1, telefon_2: phone2 } = kontakt_telefoniczny[0];

  const { email } = sekretariat[0];

  const phoneNumbers = (
    <>
      <Typography color="primary" css={showMap && 'margin-top: 1em;'}>
        KONTAKT
      </Typography>

      <NavList>
        <NavItem>
          <ContactLink
            href={`tel:${phone1}`}
            css={`
              color: ${({ theme }) => theme.colors.primary} !important;
            `}
          >
            <PhoneIcon />
            {phone1}
          </ContactLink>
        </NavItem>

        <NavItem>
          <ContactLink
            href={`tel:${phone2}`}
            css={`
              color: ${({ theme }) => theme.colors.primary} !important;
            `}
          >
            <PhoneIcon />
            {phone2}
          </ContactLink>
        </NavItem>

        <NavItem css="text-transform: none;">
          <ContactLink href={`mailto:${email}`}>
            <EmailIcon />
            {email}
          </ContactLink>
        </NavItem>
      </NavList>
    </>
  );

  return (
    <FooterBase {...rest}>
      <Container
        css={`
          @media (${media.m}) {
            padding-left: 130px;
          }

          @media (min-width: 1690px) {
            padding-left: 25px;
          }
        `}
      >
        <FooterGrid showMap={showMap}>
          <VerticalText text="KONTAKT" />

          <NavigationArea>
            <FooterSection>
              <Typography color="primary">OFERTA</Typography>

              <NavList>
                {footerMenu.map(({ label, title, path }) => (
                  <NavItem key={path}>
                    <Link to={path} title={title}>
                      {label}
                    </Link>
                  </NavItem>
                ))}
              </NavList>
            </FooterSection>
          </NavigationArea>

          <AddressArea>
            <FooterSection>
              <Typography color="primary">ADRES FIRMY</Typography>

              <NavList>
                <NavItem>{companyName}</NavItem>

                <NavItem css="text-transform: none;">
                  <a
                    href={`http://maps.google.com/maps?q=${addressString}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: addressHtml }}
                  />
                </NavItem>
              </NavList>

              {showMap && phoneNumbers}
            </FooterSection>
          </AddressArea>

          {!showMap ? (
            <>
              <ContactArea>
                <FooterSection>{phoneNumbers}</FooterSection>
              </ContactArea>

              <LogoArea>
                <Logo fluid={fluid} />
              </LogoArea>
            </>
          ) : (
            isClient && (
              <MapArea>
                <Map
                  isMarkerShown
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
                    process.env.GATSBY_GOOGLE_MAP_API_KEY
                  }&v=3.exp`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                />
              </MapArea>
              // <img
              //   src={`https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=${
              //     process.env.GATSBY_GOOGLE_MAP_API_KEY
              //   }`}
              //   css="width: 400px; height: 200px;"
              // />
              // <MapArea>
              //   <div
              //     css={`
              //       width: 100%;
              //       height: 100%;
              //       background: ${({ theme }) => theme.colors.lightgrey};
              //     `}
              //   />
              // </MapArea>
            )
          )}
        </FooterGrid>
      </Container>
    </FooterBase>
  );
};

Footer.propTypes = {
  showMap: PropTypes.bool,
};

const query = graphql`
  query {
    prismicStronaKontakt {
      data {
        nazwa_firmy
        adres_firmy {
          html
          text
        }
        sekretariat {
          email
        }
        kontakt_telefoniczny {
          telefon_1
          telefon_2
        }
      }
    }

    file(name: { eq: "promen-logo" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default React.memo(Footer);
