import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { mobileFirstMedia as media } from '../../config/theme';

import Container from '../container/container';

const HtmlContent = styled.div`
  font-size: 0.8em;

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    font-weight: inherit;
  }
`;

export const FullScreenContainer = styled(Container)`
  padding: 0;
  margin-bottom: 40px;

  @media (${media.xs}) {
    padding: 0 25px;
  }

  @media (${media.m}) {
    margin-bottom: 60px;
  }
`;

export const BannerGrid = styled.div`
  height: 320px;
  display: grid;
  grid-template-rows: 1fr var(--cropSize);
  background: lightgrey;
  position: relative;
  clip-path: polygon(
    100% 0,
    100% calc(100% - var(--cropSize)),
    calc(100% - var(--cropSize)) 100%,
    0 100%,
    0 0
  );

  @media (${media.m}) {
    height: 500px;
    grid-template-columns: 1fr 500px;
    clip-path: polygon(
      100% 0,
      100% calc(100% - 110px),
      calc(100% - 110px) 100%,
      110px 100%,
      0% calc(100% - 110px),
      0 0
    );
  }

  @media (${media.l}) {
    grid-template-columns: 1fr 600px;
  }
`;

export const Overlay = styled.div`
  grid-row: 2 / 3;
  padding: 0 var(--cropSize) 0 2em;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);

  @media (${media.m}) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.8em 2.5em 3em 5em;
    /* padding: 0 2.5em 0 4em; */
  }
`;

export const HeadingArea = styled(HtmlContent)`
  color: ${({ theme }) => theme.colors.primary};
  /* font-size: 0.8em; */

  @media (${media.m}) {
    transform: translateX(-4.1em);
    margin-bottom: 40px;
    margin-right: 50px;
    font-weight: 300;

    strong {
      font-weight: 800;
      margin-left: 50px;
    }
  }
`;

export const SubheadingArea = styled(HtmlContent)`
  margin-bottom: 60px;

  h1 {
    line-height: 1.3;
  }
`;

export const SectionContent = styled(HtmlContent)`
  display: none;

  @media (${media.m}) {
    display: block;
    font-size: 0.4em;
    padding-left: 2.5em;
    color: white;
    border-left: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const BackgroundImageArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
`;

export const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  @media (${media.m}) {
    & img {
      /* object-position: center -255px !important; */
    }
  }

  @media (${media.l}) {
    & img {
      object-position: center -100px !important;
    }
  }
`;
