import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { mobileFirstMedia as media } from '../../config/theme';

export const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  position: relative;
  /* overflow-x: hidden; */

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 50% 1fr;
  }
`;

export const HeadingArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 1 / 3;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
  }
`;

export const LinksListArea = styled.div`
  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 1 / 3;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
  }
`;

export const Image1Area = styled.div`
  margin: 0px -25px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 20px);

  @media (${media.xs}) {
    margin: 0;
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 3 / 5;
    grid-row: 1 / 4;
  }

  @media (${media.m}) {
    grid-column: 2 / 3;
  }
`;

export const Image1 = styled(GatsbyImage)`
  height: 100%;
  clip-path: polygon(
    100% 0,
    100% calc(100% - var(--cropSize)),
    calc(100% - var(--cropSize)) 100%,
    0 100%,
    0 0
  );
`;

export const ButtonArea = styled.div`
  justify-self: center;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.s}) {
    grid-column: 1 / 3;
    justify-self: end;
    align-self: flex-end;
  }

  @media (${media.m}) {
    grid-column: 1 / 2;
  }
`;
