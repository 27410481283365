import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Button from '../button/button';

import {
  FullScreenContainer,
  BannerGrid,
  Overlay,
  HeadingArea,
  SubheadingArea,
  SectionContent,
  BackgroundImageArea,
  BackgroundImage,
} from './styles';

const BannerMain = ({ title, text, image }) => (
  <FullScreenContainer width={1920}>
    <BannerGrid data-aos="fade-down" data-aos-delay="200">
      <Overlay>
        <HeadingArea dangerouslySetInnerHTML={{ __html: title }} />

        <SectionContent>
          <SubheadingArea dangerouslySetInnerHTML={{ __html: text }} />

          <Button
            component={Link}
            to="/rusztowania"
            css="margin-bottom: 13px;"
            title="Rusztowania - PRO-MEN"
          >
            ZOBACZ WSZYSTKIE
          </Button>
        </SectionContent>
      </Overlay>

      <BackgroundImageArea>
        <BackgroundImage fluid={image.image} alt={image.alt} />
      </BackgroundImageArea>
    </BannerGrid>
  </FullScreenContainer>
);

BannerMain.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.object.isRequired,
  big: PropTypes.bool,
};

export default BannerMain;
