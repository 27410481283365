import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps';

import styles from './styles.json';

const CustomMap = withGoogleMap(({ isMarkerShown }) => {
  const {
    marker: {
      childImageSharp: {
        fixed: { src: url },
      },
    },
  } = useStaticQuery(query);

  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 51.2070178, lng: 22.3856682 }}
      options={{ styles, disableDefaultUI: true }}
    >
      {isMarkerShown && (
        <Marker
          position={{ lat: 51.2070178, lng: 22.3856682 }}
          options={{ icon: { url } }}
        />
      )}
    </GoogleMap>
  );
});

const query = graphql`
  query {
    marker: file(name: { eq: "marker" }) {
      childImageSharp {
        fixed(width: 45) {
          src
        }
      }
    }
  }
`;

CustomMap.propTypes = {
  isMarkerShown: PropTypes.bool,
};

export default withScriptjs(CustomMap);
