import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '../typography/typography';
import { mobileFirstMedia as media } from '../../config/theme';

const Wrapper = styled.div`
  margin-left: calc(-0.8em - 3px);
  padding-left: 0.8em;
  border-left: 2px solid ${({ theme }) => theme.colors.primary};

  ${({ textColor }) => `color: ${textColor} !important;`};

  ${({ centered }) => (centered ? 'padding: 0 20px; margin: 0;' : null)};

  border-right: ${({ centered, theme }) =>
    centered ? `2px solid ${theme.colors.primary}` : 'none'};

  border-color: ${({ textColor }) => (textColor ? textColor : null)};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

const ColoredTypography = styled(Typography)`
  ${({ dualColor }) =>
    dualColor
      ? `
        @media (${media.m}) {
          color: white;
        }
      `
      : null}
`;

const HtmlContent = styled.div`
  font-size: 0.6em;

  ${({ textColor }) => `color: ${textColor} !important`};

  ${({ dualColor }) =>
    dualColor
      ? `
        @media (${media.m}) {
          color: white;
        }
      `
      : null}

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    font-weight: inherit;
  }
`;

const Heading = ({
  title,
  heading,
  headingVariant = 'h1',
  headingClassName = null,
  centered = false,
  color,
  dualColor = false,
  asText = false,
  ...rest
}) => (
  <Wrapper centered={centered} textColor={color} {...rest}>
    <Typography variant="p" className="h4" sectionTitle>
      {title}
    </Typography>

    {asText ? (
      <ColoredTypography
        variant={headingVariant}
        dualColor={dualColor}
        className={headingClassName}
      >
        {heading}
      </ColoredTypography>
    ) : (
      <HtmlContent
        dangerouslySetInnerHTML={{ __html: heading }}
        textColor={color}
        dualColor={dualColor}
      />
    )}
  </Wrapper>
);

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  headingVariant: PropTypes.string,
  headingClassName: PropTypes.string,
  centered: PropTypes.bool,
  color: PropTypes.string,
  dualColor: PropTypes.bool,
  asText: PropTypes.bool,
};

export default Heading;
